<template>
  <div class="tt-image-lazy">
    <img :src="imgSrcLazy" :alt="altText" />
  </div>
</template>

<script setup lang="ts">
import defaultImg from '@/assets/images/default-lazy-load.jpg'

const props = defineProps<{
  imgSrc: string
  altText?: string
}>()

const imgSrcLazy = computed(() => props.imgSrc || defaultImg)
</script>

<style lang="sass" scoped>
.tt-image-lazy
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  border-radius: 8px
  overflow: hidden
  img
    height: 100%
    width: 100%
    object-fit: cover
</style>
